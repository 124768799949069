/*говнище то какое....*/
import React from "react";
import { sectionsTexts } from "../props/sectionsTexts";
import { SectionHeading } from "../components/sectionHeading";
import { SimpleText } from "../components/simpleText";

export class Technical extends React.Component {
    render() {
        return (
            <div className="section__wrapper">
                <section className="section section__not-homepage" id="technical">
                    <SectionHeading prop={sectionsTexts.technical} />
                    <div className="section__content">
						<div className="section__lead">
							<ul>
								<li>Баннер должен представлять из себя самостоятельно работающую HTML страницу и не использовать внешние ресурсы.</li>
								<li>Все ресурсы должны быть приложены локально.</li>
								<li>Все ресурсы должны располагаться в одной директории с .html файлом.</li>
								<li>Названия файлов не должны содержать кириллицу.</li>
								<li>Баннер должен открываться и отображаться локально.</li>
								<li>Не должно присутствовать никакого функционала, препятствующего отображению баннера в iframe.</li>
								<li>Баннер не должен иметь горизонтальную или вертикальную прокрутку</li>
								<li>Готовый материал к отправке должен представлять из себя ZIP архив, содержащий в себе HTML страницу, а также прочие материалы, необходимые для отрисовки баннера: изображения, шрифты, скрипты, либо подготовленное превью.</li>
								<li>Продолжительность анимации не должна превышать 30 секунд.</li>
								<li>Общий объем несжатого баннера с дополнительными материалами не должен превышать 3 мб.</li>
							</ul>
							<div class="tpl_download_wrapper down">
								<div class="tpl_download">
									<a href="https://demo.weborama.com.ru/templates/templates.zip" class="title"><svg class="download_icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 22.6668L8.024 14.6895L23.976 14.6908L16 22.6668Z" class="fill"/><path d="M20.612 9.33325H11.3333V15.9999H20.612V9.33325Z" class="fill"/><path d="M25.3333 22.6667H6.66667V25.3334H25.3333V22.6667Z" class="fill"/><path d="M12.3333 7V5H19.612V7H12.3333Z" class="stroke" stroke-width="2"/></svg>Скачать шаблоны</a>
									<div class="select_list">
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_160x600.zip">160x600</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_240x400.zip">240x400</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_250x250.zip">250x250</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_256x256.zip">256x256</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_300x250.zip">300x250</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_300x600.zip">300x600</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_320x50.zip">320x50</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_336x280.zip">336x280</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_600x600.zip">600x600</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_728x90.zip">728x90</a>
										<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_1080x600.zip">1080x600</a>
									</div>
								</div>
							</div>
							<h3>КАСТОМИЗАЦИЯ КОДА</h3>
							<p>Для корректной работы необходимо подключить скрипт <b>screenad.js</b>. Для этого разместите в теге <b>&lt;head&gt;</b> следующую строчку:</p>
							<div class="somecode">
								<div class="code">
									<div class="code_wrapper">
										<div class="line">&lt;head&gt;</div>
										<div class="selectable"><div class="line">&lt;script type="text/javascript" src="https://cstatic.weborama-tech.ru/js/advertiserv2/ru/screenad_interface_1.0.3_scrambled.js"&gt;&lt;/script&gt;</div></div>
										<div class="line">...</div>
										<div class="line">&lt;/head&gt;</div>
									</div>
								</div>
							</div>
							<p>Кроме того, для корректного определения системой размера баннера, необходимо добавить в тег <b>&lt;head&gt;</b> строчки:</p>
							<div class="somecode">
								<div class="code">
									<div class="code_wrapper">
										<div class="line">&lt;head&gt;</div>
										<div class="line">&lt;script type="text/javascript" src="//cstatic.weborama-tech.ru/js/advertiserv2/ru/screenad_interface_1.0.3_scrambled.js"&gt;&lt;/script&gt;</div>
			<div class="selectable"><div class="line">&lt;!-- ADRIME SCREENAD META DATA (don't edit/remove) --&gt;</div>
			<div class="line">&lt;!-- SCRVERSION: screenad_interface_1.0.3 --&gt;</div>
			<div class="line">&lt;!-- SCRFORMAT: banner --&gt;</div>
			<div class="line">&lt;!-- SCRWIDTH: <b>width</b> --&gt;</div>
			<div class="line">&lt;!-- SCRHEIGHT: <b>height</b> --&gt;</div></div>
										<div class="line">...</div>
										<div class="line">&lt;/head&gt;</div>
									</div>
								</div>
							</div>
							<div>где <b>width</b> — ширина баннера, <b>height</b> — высота баннера</div>
							<p>В случае, если баннер представляет из себя адаптивную перетяжку (100% ширины), необходимо для параметра <b>SCRWIDTH</b> указать значение <b>100</b>.</p>
							<p>Для полноэкранных креативов (100% ширины и 100% высоты), необходимо, дополнительно указать значение <b>100</b> для параметра <b>SCRHEIGHT</b>.</p>
							<p>Кроме того необходимо вызвать функцию screenad.position():</p>
							<div class="somecode">
								<div class="code">
									<div class="code_wrapper">
<div class="line">&lt;script&gt;</div>
<div class="selectable"><div class="line">var bannerWidth = <b>"100%"</b>; //ширина креатива</div>
<div class="line">var bannerHeight = <b>250</b>; //высота креатива (для полноэкранного креатива необходимо передать значение "100%")</div>
<div class="line">screenad.position('width=' + bannerWidth + '&height=' + bannerHeight + '&hor=left&ver=banner&hide=false&sticky=false&offx=0&offy=0 &cliprect=auto,auto,auto,auto&antizoom=false&smooth=0');</div></div>
<div class="line">&lt;/script&gt;</div>
									</div>
								</div>
							</div>
							<h3>ДОБАВЛЕНИЕ КЛИКОВЫХ ССЫЛОК</h3>
							<p>Основная кликовая ссылка размещается нашим трафик-менеджером, и из баннера недоступна. Поэтому, для того, чтобы инициировать переход по кликовой ссылке, необходимо выполнить js код: <b>screenad.click('default');</b> в обработчике события клика:</p>
							<div class="somecode">
								<div class="code">
									<div class="code_wrapper">
										<div class="line">&lt;script&gt;</div>
										<div class="line">...</div>
			<div class="selectable"><div class="line"><SimpleText prop={sectionsTexts.technical.codeShit} /></div>
				<div class="line">&emsp;<b>screenad.click("default");</b></div>
			<div class="line">});</div></div>
										<div class="line">...</div>
										<div class="line">&lt;/script&gt;</div>
									</div>
								</div>
							</div>
							<div>где <b>.clickarea</b> — селектор кликовой области</div>
							<p>Или непосредственно в html:</p>
							<div class="somecode">
								<div class="code">
									<div class="code_wrapper">
										<div class="line">&lt;body&gt;</div>
										<div class="line">...</div>
										<div class="selectable"><div class="line">&emsp;&lt;button class="clickarea" onClick=<b>"screenad.click('default');"</b>" return false;"&gt;&lt;/button&gt;</div></div>
										<div class="line">...</div>
										<div class="line">&lt;/body&gt;</div>
									</div>
								</div>
							</div>
							<p>Если требуется установить более одной ссылки, то для каждой кликовой области при вызове метода <b>click()</b>, необходимо, в качестве аргумента передавать “<b>extraN</b>”, где <b>N</b> — уникальный номер для каждой ссылки:</p>
							<div class="somecode">
								<div class="code">
									<div class="code_wrapper">
										<div class="line">&lt;body&gt;</div>
										<div class="line">...</div>
			<div class="selectable"><div class="line">&emsp;&lt;button class="clickarea" onClick=<b>"screenad.click('default');"</b>" return false;"&gt;&lt;/button&gt;</div>
			<div class="line">&emsp;&lt;button class="clickarea1" onClick=<b>"screenad.click('extra1');"</b>" return false;"&gt;&lt;/button&gt;</div>
			<div class="line">&emsp;&lt;button class="clickarea2" onClick=<b>"screenad.click('extra2');"</b>" return false;"&gt;&lt;/button&gt;</div></div>
										<div class="line">...</div>
										<div class="line">&lt;/body&gt;</div>
									</div>
								</div>
							</div>
							<h3>ДОБАВЛЕНИЕ УЧЕТА СОБЫТИЙ</h3>
							<p>Если требуется вести учет событий, необходимо по наступлени и события вызвать метод <b>event()</b>, передав в качестве аргумента имя события, как оно должно быть отражено в статистике. Имена событий могут содержать символы: <b>A-Z a-z 0-9 _</b>:</p>
							<div class="somecode">
								<div class="code">
									<div class="code_wrapper">
										<div class="line">&lt;script&gt;</div>
										<div class="line">...</div>
			<div class="selectable"><div class="line"><SimpleText prop={sectionsTexts.technical.codeShit2} /></div>
				<div class="line">&emsp;<b>screenad.event("area_mouseenter");</b></div>
			<div class="line">});</div></div>
										<div class="line">...</div>
										<div class="line">&lt;/script&gt;</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tpl_download_wrapper">
							<div class="tpl_download">
								<a href="https://demo.weborama.com.ru/templates/templates.zip" class="title"><svg class="download_icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 22.6668L8.024 14.6895L23.976 14.6908L16 22.6668Z" class="fill"/><path d="M20.612 9.33325H11.3333V15.9999H20.612V9.33325Z" class="fill"/><path d="M25.3333 22.6667H6.66667V25.3334H25.3333V22.6667Z" class="fill"/><path d="M12.3333 7V5H19.612V7H12.3333Z" class="stroke" stroke-width="2"/></svg>Скачать шаблоны</a>
								<div class="select_list">
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_160x600.zip">160x600</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_240x400.zip">240x400</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_250x250.zip">250x250</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_256x256.zip">256x256</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_300x250.zip">300x250</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_300x600.zip">300x600</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_320x50.zip">320x50</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_336x280.zip">336x280</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_600x600.zip">600x600</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_728x90.zip">728x90</a>
									<a class="select_item" rel="noreferrer" target="_blank" href="https://demo.weborama.com.ru/templates/weborama_1080x600.zip">1080x600</a>
								</div>
							</div>
						</div>
						
						
                    </div>
                </section>
            </div>
        );
    }
}
